import React, { Component } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import Link from 'gatsby-link'
import Clipboard from 'react-clipboard.js';
import '../css/index.css'
import '../css/Footer.css'

class Footer extends Component {
    render() {
      return (
        <div className="Footer">
            <div className="FooterGroup">
                <p className="copyright">© 2020 Jemma Kwak</p>
                <h2>Get in touch</h2>
                <div className="FooterLinks">
                    <a className="FooterLink" href="https://www.twitter.com/jemmakwak">Twitter</a>
                    <a className="FooterLink" href="https://linkedin.com/in/jemmakwak/">LinkedIn</a>
                    <a className="FooterLink" href="https://www.goodreads.com/user/show/48253851-jemma-kwak">Goodreads</a>
                    <a className="FooterLink" href="https://www.figma.com/@jemma">Figma</a>
                </div>
            </div>
        </div>
      )
    }
  }
  
  export default Footer;